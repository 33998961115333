@layer components {
    .font-dharma {
        @apply !font-[dharma-gothic-e];
    }

    .font-bio {
        @apply !font-[bio-sans];
    }




    /*TITLES*/

    .display-homestead {
        @apply font-dharma text-[3.5rem] !font-[800] leading-[56px] tracking-[1.75px];
    }

    .display-brave-the-dark {
        @apply !font-apotek text-[3.5rem] !font-[700] leading-[56px] tracking-[1.75px];
    }

    .display-rule-breakers {
        @apply !font-citrusGothic text-[3.5rem] !font-[400] leading-[56px] tracking-[1px];
    }

    .title-xxl-brave-the-dark {
        @apply !font-apotek text-[3rem] !font-[700] leading-[48px] tracking-[1.5px];
    }

    .title-xxl-rule-breakers {
        @apply !font-citrusGothic text-[3rem] !font-[400] leading-[48px] tracking-[0.75px];
    }

    .title-xl-homestead {
        @apply font-dharma text-[2.5rem] !font-[800] leading-[40px] tracking-[1.5px];
    }

    .title-xl-brave-the-dark {
        @apply !font-apotek text-[2.5rem] !font-[700] leading-[40px] tracking-[1.5px];
    }

    .title-xl-rule-breakers {
        @apply !font-citrusGothic text-[2.5rem] !font-[400] leading-[40px] tracking-[0.75px];
    }

    .title-lg-homestead {
        @apply font-dharma text-[2rem] !font-[800] leading-[32px] tracking-[1.5px];
    }

    .title-lg-brave-the-dark {
        @apply !font-apotek text-[2rem] !font-[700] leading-[32px] tracking-[1.5px];
    }

    .title-lg-rule-breakers {
        @apply !font-citrusGothic text-[2rem] !font-[400] leading-[32px] tracking-[0.75px];
    }

    .title-md-homestead {
        @apply font-dharma text-[1.75rem] !font-[800] leading-[28px] tracking-[1.5px];
    }

    .title-md-brave-the-dark {
        @apply !font-apotek text-[1.75rem] !font-[500] leading-[28px] tracking-[1.5px];
    }

    .title-md-rule-breakers {
        @apply !font-citrusGothic text-[1.75rem] !font-[400] leading-[28px] tracking-[0.5px];
    }

    .title-sm-homestead {
        @apply font-bio text-[1.313rem] !font-[600] leading-[27.3px];
    }

    .title-sm-brave-the-dark {
        @apply !font-apotek text-[1.5rem] font-semibold leading-[31px];
    }

    .title-sm-rule-breakers {
        @apply !font-roboto text-[1.5rem] !font-[700] leading-[31px];
    }

    .title-xs-homestead {
        @apply font-bio text-[1.125rem] !font-[600] leading-[23.4px];
    }

    .title-xs-brave-the-dark {
        @apply !font-apotek text-[1.25rem] !font-[600] leading-[26px] tracking-[0.5px];
    }

    .title-xs-rule-breakers {
        @apply !font-roboto text-[1.25rem] !font-[700] leading-[26px];
    }




    /*EYEBROWS*/

    .eyebrow-xl-brave-the-dark {
        @apply !font-apotek text-[1.313rem] !font-[600] leading-[21px] tracking-[1px] uppercase;
    }

    .eyebrow-xl-rule-breakers {
        @apply !font-citrusGothic text-[1.313rem] !font-[400] leading-[21px] tracking-[1px] uppercase;
    }


    .eyebrow-lg-homestead {
        @apply font-bio text-base !font-[600] leading-[16px] tracking-[0.5px] uppercase;
    }

    .eyebrow-lg-rule-breakers {
        @apply !font-citrusGothic text-base !font-[400] leading-[16px] tracking-[1px] uppercase;
    }

    .eyebrow-lg-brave-the-dark {
        @apply !font-apotek text-[1.125rem] !font-[600] leading-[18px] tracking-[1px] uppercase;
    }

    .eyebrow-md-homestead {
        @apply font-bio text-sm !font-[600] leading-[14px] tracking-[0.5px] uppercase;
    }

    .eyebrow-md-brave-the-dark {
        @apply !font-apotek text-base !font-[600] leading-[16px] tracking-[0.75px] uppercase;
    }

    .eyebrow-md-rule-breakers {
        @apply !font-citrusGothic text-base !font-[400] leading-[16px] tracking-[0.75px] uppercase;
    }

    .eyebrow-sm-homestead {
        @apply font-bio text-xs !font-[600] leading-[12px] tracking-[0.5px] uppercase;
    }

    .eyebrow-sm-brave-the-dark {
        @apply !font-apotek text-[0.875rem] !font-[600] leading-[14px] tracking-[0.5px] uppercase;
    }

    .eyebrow-sm-rule-breakers {
        @apply !font-citrusGothic text-[0.875rem] !font-[400] leading-[14px] tracking-[0.5px] uppercase;
    }

    /*PARAGRAPH*/

    .paragraph-lg-homestead {
        @apply block font-bio !font-[400] text-[1.313rem] leading-[31.5px];
    }

    .paragraph-lg-brave-the-dark {
        @apply block !font-roboto !font-[400] text-[1.125rem] leading-[27px];
    }
    .paragraph-lg-rule-breakers{
        @apply block !font-roboto !font-[400] text-[1.125rem] leading-[27px];
    }

    .paragraph-md-homestead {
        @apply block font-bio !font-[400] text-[1.125rem] leading-[27px];
    }

    .paragraph-md-brave-the-dark {
        @apply block !font-roboto !font-[400] text-[1rem] leading-[24px];
    }

    .paragraph-md-rule-breakers {
        @apply block !font-roboto !font-[400] text-[1rem] leading-[24px];
    }

    .paragraph-sm-homestead {
        @apply block font-bio !font-[400] text-base leading-[24px];
    }

    .paragraph-sm-brave-the-dark {
        @apply block !font-roboto !font-[400] text-[0.875rem] leading-[21px];
    }

    .paragraph-sm-rule-breakers {
        @apply block !font-roboto !font-[400] text-[0.875rem] leading-[21px];
    }

    /*CAPTIONS*/

    .caption-md-homestead {
        @apply font-bio text-base !font-[400] leading-[16px];
    }

    .caption-md-brave-the-dark {
        @apply !font-roboto text-base !font-[400] leading-[16px];
    }

    .caption-md-rule-breakers {
        @apply !font-roboto text-base !font-[400] leading-[16px];
    }

    .caption-sm-homestead {
        @apply font-bio text-sm !font-[400] leading-[14px];
    }

    .caption-sm-brave-the-dark {
        @apply !font-roboto !font-[400] text-sm leading-[14px];
    }

    .caption-sm-rule-breakers {
        @apply !font-roboto !font-[400] text-sm leading-[14px];
    }


    /*FOOTER*/

    .footer-md-homestead {
        @apply font-bio text-[0.75rem] !font-[400] leading-[18px];
    }

    .footer-md-brave-the-dark {
        @apply !font-roboto text-[0.75rem] !font-[400] leading-[18px];
    }

    .footer-md-rule-breakers {
        @apply !font-roboto text-[0.75rem] !font-[400] leading-[18px];
    }

    .footer-sm-homestead {
        @apply font-bio text-[0.625rem] !font-[400] leading-[15px];
    }

    .footer-sm-brave-the-dark {
        @apply !font-roboto text-[0.625rem] !font-[400] leading-[15px];
    }

    .footer-sm-rule-breakers {
        @apply !font-roboto text-[0.625rem] !font-[400] leading-[15px];
    }

    /*BUTTONS*/

    .btn-md-homestead {
        @apply font-bio text-[1.125rem] !font-[600] leading-[19.8px];
    }

    .btn-md-brave-the-dark {
        @apply !font-apotek text-[1.125rem] !font-[600] leading-[19px] tracking-[1px];
    }

    .btn-md-rule-breakers {
        @apply !font-citrusGothic text-[1.125rem] !font-[400] leading-[19px] tracking-[0.5px];
    }

    .btn-sm-homestead {
        @apply font-bio text-base !font-[600] leading-[19.2px];
    }

    .btn-sm-brave-the-dark {
        @apply !font-apotek text-base !font-[600] leading-[16px] tracking-[0.75px];
    }

    .btn-sm-rule-breakers {
        @apply !font-citrusGothic text-base !font-[400] leading-[16px] tracking-[0.5px];
    }

    .btn-xs-homestead {
        @apply font-bio text-sm !font-[600] leading-[16.8px];
    }

    .btn-xs-brave-the-dark {
        @apply !font-apotek text-sm !font-[600] leading-[15px] tracking-[0.5px];
    }

    .btn-xs-rule-breakers {
        @apply !font-citrusGothic text-sm !font-[400] leading-[15px] tracking-[0.25px];
    }



    /*CUSTOM*/

    .promo-text-sm-homestead {
        @apply font-bio text-[1rem] !font-[400] leading-[21px];
    }

    .promo-text-sm-brave-the-dark {
        @apply !font-roboto text-[1rem] !font-[400] leading-[21px];
    }

    .promo-text-sm-rule-breakers {
        @apply !font-roboto text-[1rem] !font-[400] leading-[21px];
    }


    .promo-text-md-homestead {
        @apply font-bio text-[1.125rem] !font-[400] leading-[24px];
    }

    .promo-text-md-brave-the-dark {
        @apply !font-roboto text-[1.125rem] !font-[400] leading-[24px];
    }

    .promo-text-md-rule-breakers {
        @apply !font-roboto text-[1.125rem] !font-[400] leading-[24px];
    }

    .ticket-value-homestead {
        @apply font-bio text-[2.25rem] !font-[600] leading-[36px];
    }

    .ticket-value-brave-the-dark {
        @apply !font-apotek text-[2.25rem] !font-[600] leading-[36px];
    }

    .ticket-value-rule-breakers {
        @apply !font-citrusGothic text-[2.25rem] !font-[400] leading-[36px];
    }

    .step-value-xl-homestead {
        @apply font-bio text-[1.25rem] !font-[700] leading-[30px] tracking-[0.5px];
    }

    .step-value-xl-brave-the-dark {
        @apply !font-apotek text-[1.25rem] !font-[700] leading-[30px] tracking-[0.5px];
    }

    .step-value-xl-rule-breakers {
        @apply !font-citrusGothic text-[1.25rem] !font-[400] leading-[30px];
    }

    .step-value-lg-homestead {
        @apply font-bio text-[1.125rem] !font-[700] leading-[27px] tracking-[0.5px];
    }

    .step-value-lg-brave-the-dark {
        @apply !font-apotek text-[1.125rem] !font-[700] leading-[27px] tracking-[0.5px];
    }

    .step-value-lg-rule-breakers {
        @apply !font-citrusGothic text-[1.125rem] !font-[400] leading-[27px];
    }

    .step-value-md-homestead {
        @apply font-bio text-[1rem] !font-[700] leading-[24px] tracking-[0.5px];
    }

    .step-value-md-brave-the-dark {
        @apply !font-apotek text-[1rem] !font-[700] leading-[24px] tracking-[0.5px];
    }

    .step-value-md-rule-breakers {
        @apply !font-citrusGothic text-[1rem] !font-[400] leading-[24px];
    }

    .tab-text-homestead {
        @apply font-bio text-[1.125rem] !font-[600] leading-[18px] tracking-[0.5px];
    }

    .tab-text-brave-the-dark {
        @apply !font-apotek text-[1.25rem] !font-[600] leading-[18px] tracking-[1px];
    }

    .tab-text-rule-breakers {
        @apply !font-citrusGothic text-[1.25rem] !font-[400] leading-[18px] tracking-[0.5px];
    }









}
