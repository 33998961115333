:root {
    --giveaway-primary: #2C2C33;
    --giveaway-primary-light: #D3D3D6;
    --giveaway-primary-dark: #1B1B21;
    --giveaway-primary-accent: #535F66;
    --giveaway-primary-accent-light: #AAAFB4;
    --giveaway-primary-accent-dark: #3E4D59;
    --giveaway-primary-subtle: #E2DDCF;
    --giveaway-primary-subtle-light: #F0EEE7;
    --giveaway-primary-subtle-dark: #C5C1B6;
    --giveaway-subtle: #535F66;
    --giveaway-secondary-accent: #EB2E27;
    --giveaway-secondary-accent-light: #F59695;
    --giveaway-secondary--accent-dark: #D11919;
    --giveaway-secondary: #F3B61F;
    --giveaway-secondary-light: #F9DA8F;
    --giveaway-secondary-dark: #DB9905;
    --giveaway-background-primary-light: #FFFFFF;
    --giveaway-background-primary-dark: #1B1B21;
    --giveaway-background-subtle-light: #EBE4D4;
    --giveaway-background-subtle-dark: #262523;
    --giveaway-background-subtlest-light: #F5F5F5;
    --giveaway-background-subtlest-dark:  #2C2C33;
    --giveaway-text-primary-light: #2C2C33;
    --giveaway-text-primary-dark: #FFFFFF;
    --giveaway-text-subtle-light: #535F66;
    --giveaway-text-subtle-dark: #AAAFB4;
}


[data-slug="brave-the-dark"] {
    --giveaway-primary: #14293D;
    --giveaway-primary-light: #C9D1D7;
    --giveaway-primary-dark: #0C1C29;
    --giveaway-primary-accent: #3A4C63;
    --giveaway-primary-accent-light: #C1C9D2;
    --giveaway-primary-accent-dark: #283648;
    --giveaway-primary-subtle: #E2C6A2;
    --giveaway-primary-subtle-light: #F6EDE4;
    --giveaway-primary-subtle-dark: #B49A7A;
    --giveaway-secondary: #FFA337;
    --giveaway-subtle: #535F66;
    --giveaway-secondary-light: #FFD4A0;
    --giveaway-secondary-dark: #B57224;
    --giveaway-secondary-accent: #AE3232;
    --giveaway-secondary-accent-light: #E0BEBE;
    --giveaway-secondary-accent-dark: #732626;
    --giveaway-background-primary-light: #FFFFFF;
    --giveaway-background-primary-dark: #0C1C29;
    --giveaway-background-subtle-light: #E6E8EA;
    --giveaway-background-subtle-dark: #1D2835;
    --giveaway-background-subtlest-light: #F7F7F8;
    --giveaway-background-subtlest-dark: #283648;
    --giveaway-text-primary-light: #14293D;
    --giveaway-text-primary-dark: #C9D1D7;
    --giveaway-text-subtle-light: #3A4C63;
    --giveaway-text-subtle-dark: #C1C9D2;
}

[data-slug="rule-breakers"] {
    --giveaway-primary: #14293D;
    --giveaway-primary-light: #C9D1D7;
    --giveaway-primary-dark: #0C1C29;
    --giveaway-primary-accent: #3A4C63;
    --giveaway-primary-accent-light: #C1C9D2;
    --giveaway-primary-accent-dark: #283648;
    --giveaway-primary-subtle: #E2C6A2;
    --giveaway-primary-subtle-light: #F6EDE4;
    --giveaway-primary-subtle-dark: #B49A7A;
    --giveaway-subtle: #535F66;
    --giveaway-secondary: #FFA337;
    --giveaway-secondary-light: #FFD4A0;
    --giveaway-secondary-dark: #B57224;
    --giveaway-secondary-accent: #AE3232;
    --giveaway-secondary-accent-light: #E0BEBE;
    --giveaway-secondary-accent-dark: #732626;
    --giveaway-background-primary-light: #FFFFFF;
    --giveaway-background-primary-dark: #0C1C29;
    --giveaway-background-subtle-light: #E6E8EA;
    --giveaway-background-subtle-dark: #1D2835;
    --giveaway-background-subtlest-light: #F7F7F8;
    --giveaway-background-subtlest-dark: #283648;
    --giveaway-text-primary-light: #14293D;
    --giveaway-text-primary-dark: #C9D1D7;
    --giveaway-text-subtle-light: #3A4C63;
    --giveaway-text-subtle-dark: #C1C9D2;
}

[data-slug="homestead"] {
    --giveaway-primary: #2C2C33;
    --giveaway-primary-light: #D3D3D6;
    --giveaway-primary-dark: #1B1B21;
    --giveaway-primary-accent: #535F66;
    --giveaway-primary-accent-light: #AAAFB4;
    --giveaway-primary-accent-dark: #3E4D59;
    --giveaway-primary-subtle: #E2DDCF;
    --giveaway-primary-subtle-light: #F0EEE7;
    --giveaway-primary-subtle-dark: #C5C1B6;
    --giveaway-subtle: #535F66;
    --giveaway-secondary-accent: #EB2E27;
    --giveaway-secondary-accent-light: #F59695;
    --giveaway-secondary--accent-dark: #D11919;
    --giveaway-secondary: #F3B61F;
    --giveaway-secondary-light: #F9DA8F;
    --giveaway-secondary-dark: #DB9905;
    --giveaway-background-primary-light: #FFFFFF;
    --giveaway-background-primary-dark: #1B1B21;
    --giveaway-background-subtle-light: #EBE4D4;
    --giveaway-background-subtle-dark: #262523;
    --giveaway-background-subtlest-light: #F5F5F5;
    --giveaway-background-subtlest-dark:  #2C2C33;
    --giveaway-text-primary-light: #14293D;
    --giveaway-text-primary-dark: #C9D1D7;
    --giveaway-text-subtle-light: #3A4C63;
    --giveaway-text-subtle-dark: #C1C9D2;
}
